<template>
  <div class="categoryWrapper">
    <categoryTagInput
      :result="result"
      :field="field"
      :fieldAttributes="{ ...fieldAttributes, IndependentOption: options }"
      v-on="$listeners"
      @input="updateOptionValue"
      :value="value"
    ></categoryTagInput>
  </div>
</template>
<script>
import categoryTagInput from "@/components/dynamic/categoryTagInput.vue";
export default {
  components: { categoryTagInput },
  props: ["result", "fieldAttributes", "field","value"],
  data() {
    return { options: [] };
  },
  created() {
    this.getCategory(this.result.valueLine.n_type, this.result.tableUrl);

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === "subscriberCategoriesChanged" &&
        this.result.valueLine.n_type == 1
      ) {
        this.options = state.subscriberCategories;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  watch: {
    "this.result.valueLine.n_type": function (newValue) {
      this.options = this.getCategory(newValue, this.result.tableUrl);
    },
  },

  methods: {
    updateOptionValue(value) {
      if (
        this.result.valueLine.n_type == 1 &&
        !this.options.includes(value)
      ) {
        this.options = [...this.options, ...value];
        this.$store.commit("subscriberCategoriesChanged", this.options);
      }
    },
    getCategory(subscriberTypeVal, url) {
      let caller = this;
      //  return new Promise((resolve) => {
      console.log(subscriberTypeVal);
      if (
        this.$store.state.subscriberCategories.length > 0 &&
        subscriberTypeVal == 1
      ) {
        this.options = this.$store.state.subscriberCategories;
      } else {
        caller.frameworkAxiosRequest({
          method: 'post',
          url: url,
          data: {
            function: 'getSubscribersCategories',
            type: subscriberTypeVal,
            requestType: 'ajax',
          },
        })
            .then(function (post) {
            //console.log("here", post);
            caller.options = post.data.result.json;
            if (subscriberTypeVal == 1) {
              caller.$store.commit(
                "subscriberCategoriesChanged",
                caller.options
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });
        // });
      }
    },
  },
};
</script>